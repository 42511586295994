@import 'variables';

@font-face {
  font-family: OswaldRegular;
  src: url('./fonts/OswaldRegular.ttf') format('opentype'); }

* {
  box-sizing: border-box; }

html, body {
  padding: 0;
  margin: 0;
  font-family: OswaldRegular, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  overflow-x: hidden; }

img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%; }

.page {
  min-height: calc(100vh - #{$footer_height}); }
